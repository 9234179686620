/*
###############

CHECKBOX COMPONENT

###############
*/
.checkbox-group {
    .checkbox-group-label {
        @apply text-sm font-normal text-gray-600 pb-2 flex flex-col;
    }

    .checkbox-group-container {
        @apply space-y-5;
    }

    .checkbox-group-message {
        @apply text-gray-500 text-xs block pt-1;
    }

    &.invalid {
        .checkbox-group-message {
            @apply text-danger;
        }

        .checkbox-group-label {
            @apply text-danger;
        }
    }
}

.checkbox {
    @apply flex items-center relative my-0.5;
}

.checkbox input {
    @apply h-4 w-4 border-gray-500 rounded; 
}

.checkbox input:focus {
    outline: none; 
    box-shadow: none; 
    border-color: transparent;
}

.checkbox input:checked {
    outline: none;
    box-shadow: none;
    border-color: transparent; 
}

.checkbox label {
    @apply ml-2 block text-sm text-gray-800 font-medium;
}

.checkbox .checkbox-message {
    @apply text-gray-500 pl-2 text-xs absolute -bottom-4;
}

.checkbox.invalid .checkbox-message {
    @apply text-danger;
}

/*
CHECKBOX PRIMARY COLOR
 */
.checkbox.primary:not(:disabled) {
    @apply text-primary;
}

.checkbox.primary input:not(:disabled) {
    @apply focus:border-primary;
    @apply focus:ring-primary;
    @apply text-primary;
}

/*
Checkbox validation error
 */
.checkbox.invalid input {
    @apply border-2 border-danger focus:border-danger focus:ring-danger;
}

.checkbox.invalid label {
    @apply text-danger;
}

.checkbox input:not(:disabled),
.checkbox input:not(:read-only) {
    @apply cursor-pointer;
}

.checkbox.disabled label {
    @apply text-opacity-50 #{!important};
}

.checkbox input:disabled {
    @apply text-gray-400 bg-gray-200 border-gray-400 #{!important};
    &:checked {
        background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%23D1D5DB' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
    }
}
