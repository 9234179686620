shadow-primary-shadow/*
###############

SIDEBAR COMPONENT

###############
*/
.sidebar .mobile {
    @apply mt-5 flex-1 h-0 overflow-y-auto;
}

.sidebar .computer {
    @apply flex-grow flex flex-col w-[256px];
}

.sidebar .mobile nav {
    @apply space-y-1;
}

.sidebar .computer nav {
    @apply flex-1 px-6 pb-4 space-y-1;
}

.sidebar .computer nav div:nth-child(9) {
    @apply mt-auto #{!important};
}

.sidebar .item {
    @apply rounded-lg text-sm py-2.5 px-3 mb-1.5 flex items-center leading-snug;
}

.sidebar .item.title {
    @apply text-sm;
}

.sidebar .item.active {
    @apply rounded-lg shadow-primary-card bg-primary text-white #{!important};
}

.sidebar .item.inactive {
    @apply text-gray-500 font-normal hover:bg-gray-300 hover:text-black;
}

.sidebar .item .icon {
    @apply flex-shrink-0;
}

.sidebar .close-button {
    @apply ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white;
}

.custom-border {
    position: relative;
}

.custom-border::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 3px;
    height: calc(100% - 28px);
    background-color: #f6f6f6;
}
